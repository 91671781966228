/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

export interface EditTextListDialogData {
    list: string[];
}

@Component({
    selector: 'app-edit-text-list',
    templateUrl: './edit-text-list.component.html',
    styleUrls: ['./edit-text-list.component.scss'],
})
export class EditTextListComponent implements OnInit {
    myControl = new FormControl();

    textList: string[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: EditTextListDialogData, private _utilsService: UtilsService) {
        this.textList = data.list;
    }

    ngOnInit(): void {}

    deleteMessage(i: number) {
        this.textList.splice(i, 1);
    }
    addMessage() {
        if (!this.myControl.value) return;
        
        this.textList.push(this.myControl.value);
        this.myControl.setValue('');
    }
    
    closeDialog() {
        this.addMessage();
        this._utilsService.closeEditTextListDialog(this.textList);
    }
}
