/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { faFilePdf, faInbox } from '@fortawesome/free-solid-svg-icons';

import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cause } from 'src/app/interfaces/cause';
import { Emplacement } from 'src/app/interfaces/emplacement';
import { Mark } from 'src/app/interfaces/mark';
import { Observation } from 'src/app/interfaces/observation';
import { Part } from 'src/app/interfaces/part';
import { getFormControls, priority_status, task_status, WaterTask } from 'src/app/interfaces/water-task';
import { Zone } from 'src/app/interfaces/zone';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Result } from '../../../interfaces/result';
import { Planning } from '../../../interfaces/planning';
import { WaterRoute } from 'src/app/interfaces/water-route';
import { Itac } from 'src/app/interfaces/itac';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PlanningDetail } from '../../../interfaces/planning-detail';

/**
 * Represents the Task Assign component.
 * This component is responsible for assigning tasks and managing task-related data.
 */
@Component({
    selector: 'app-task-assign',
    templateUrl: './task-assign.component.html',
    styleUrls: ['./task-assign.component.scss'],
})
export class TaskAssignComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    loading: boolean = false;
    task?: WaterTask = {};
    observations: string[] = [];
    parts: string[] = [];
    zones: string[] = [];
    anomalies: string[] = [];
    causes: string[] = [];
    results: string[] = [];
    plannings: string[] = [];
    planningDetails: string[] = [];
    marks: string[] = [];
    emplacements: string[] = [];

    closedStatus = task_status.CLOSED;
    taskFormData: FormGroup;
    timerInputChanged: any;

    closeTaskFields = [
        'FECH_CIERRE',
        'fecha_realizacion',
        'fecha_informe_servicios',
        'OBSERVA',
        'servicios',
        'last_service',
        'suministros',
    ]

    /**
     * Constructs a new instance of the TaskAssignComponent.
     * @param _apiService - The ApiService instance used for making API calls.
     * @param utilsService - The UtilsService instance used for utility functions.
     * @param spinner - The NgxSpinnerService instance used for displaying spinners.
     */
    constructor(
        private _apiService: ApiService,
        public utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {
        this.taskFormData = getFormControls();
        this.taskFormData.controls['ANOMALIA'] = new FormControl();
        this.taskFormData.controls['codigo_de_geolocalizacion'] = new FormControl();
        this.taskFormData.controls['Numero_de_ABONADO'] = new FormControl();
        this.taskFormData.controls['prioridad'] = new FormControl();
        this.taskFormData.controls['status_tarea'] = new FormControl();
        this.taskFormData.controls['TIPORDEN'] = new FormControl();
        this.taskFormData.controls['bloque'] = new FormControl();
    }

    /**
     * Initializes the component and fetches data from the API.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        this.observations = (await this._apiService.getObservations())
            .map((obs: Observation) => `${obs.codigo_observacion} - ${obs.observacion}`)
            .sort();
        this.parts = (await this._apiService.getParts())
            .map((part: Part) => `${part.pieza}`)
            .sort();
        this.zones = (await this._apiService.getZones())
            .map((zone: Zone) => `${zone.codigo_zona} - ${zone.zona}`)
            .sort();
        this.anomalies = (await this._apiService.getCauses())
            .map((cause: Cause) => `${cause.codigo_causa}`)
            .sort();
        this.causes = (await this._apiService.getCauses())
            .map((cause: Cause) => `${cause.codigo_causa} - ${cause.causa}`)
            .sort();
        this.results = (await this._apiService.getResults())
            .map((result: Result) => `${result.codigo_resultado} - ${result.resultado}`)
            .sort();
        this.plannings = (await this._apiService.getPlannings())
            .map((planning: Planning) => `${planning.codigo_planning} - ${planning.planning}`)
            .sort();
        this.planningDetails = (await this._apiService.getPlanningDetails())
            .map((planningDetail: PlanningDetail) => `${planningDetail.planning_details}`)
        this.marks = (await this._apiService.getMarks())
            .map((mark: Mark) => `${mark.codigo_marca} - ${mark.marca} - ${mark.modelo}`)
            .sort();
        this.emplacements = (await this._apiService.getEmplacements())
            .map((emplacement: Emplacement) => `${emplacement.codigo_emplazamiento}`)
            .sort();
        await this.setInputsValueChanged();
        this.showLoading(false);
    }

    /**
     * Sets up the value change subscriptions for the input controls in the task form.
     * Whenever the value of an input control changes, the corresponding callback method is called.
     */
    async setInputsValueChanged(): Promise<void> {
        this.taskFormData.controls['causa_origen'].valueChanges.subscribe(async (cause: any) => {
            this.onCauseChange(cause);
        });
        this.taskFormData.controls['AREALIZARDV'].valueChanges.subscribe(async (causeReturn: any) => {
            this.onCauseReturnChange(causeReturn);
        });
        this.taskFormData.controls['EMPLAZADV'].valueChanges.subscribe(async (emplacement: any) => {
            this.onEmplacementChange(emplacement);
        });
        this.taskFormData.controls['prioridad'].valueChanges.subscribe(async (priority: any) => {
            try { await this.checkHibernation(priority); } catch (err) {}
        });
        this.taskFormData.controls['status_tarea'].valueChanges.subscribe(async (status: any) => {
            await this.onStatusChange(status);
        });
        this.taskFormData.controls['codigo_de_geolocalizacion'].valueChanges.subscribe(async (code: any) => {
            this.onEmplacementCodeChange(code)
        });
    }

    /**
     * Handles the change event for the emplacement code input.
     * 
     * @param code - The new value of the emplacement code.
     */
    onEmplacementCodeChange(code: any): void {
        clearTimeout(this.timerInputChanged);
        if(this.utilsService.validateValue(this.taskFormData.controls['codigo_de_geolocalizacion'])) return;
        this.timerInputChanged = setTimeout(async () => {
            await this.onGeolocationChange(code);
        }, 1000);
    }

    /**
     * Handles the change event of the cause input.
     * 
     * @param cause - The cause value selected by the user.
     */
    onCauseChange(cause: any): void { 
        if (cause) {
            const anomaly = cause.split('-')[0].trim();
            const orderAction = this.utilsService.getOrderActionFromCauseCode(anomaly);
            this.taskFormData.controls['accion_ordenada'].setValue(orderAction);
        }
    }

    /**
     * Handles the change event of the causeReturn input.
     * 
     * @param causeReturn - The causeReturn value.
     */
    onCauseReturnChange(causeReturn: any): void { 
        if (causeReturn) {
            const anomaly = causeReturn.split('-')[0].trim();
            const orderAction = this.utilsService.getOrderActionFromCauseCode(anomaly);
            this.taskFormData.controls['intervencidv'].setValue(orderAction);
        }
    }

    /**
     * Handles the change event when the emplacement value is selected.
     * Updates the RESTO_EM control value based on the selected emplacement.
     * 
     * @param emplacement - The selected emplacement value.
     */
    onEmplacementChange(emplacement: any): void { 
        if (emplacement) {
            const emplacementFound = this.utilsService.emplacements.find(
                (element) => element.codigo_emplazamiento == emplacement
            );
            if (emplacementFound)
                this.taskFormData.controls['RESTO_EM'].setValue(emplacementFound.resto);
        }
    }

    /**
     * Handles the status change event.
     * @param status - The new status value.
     */
    async onStatusChange(status: number): Promise<void> {
        try {
            if(status == this.closedStatus) {
                const question = '¿Desea añadir servicios y suministros?';
                const res = await this.utilsService.openQuestionDialog('Seleccione', question);
                if(res) await this.requestServices();
            }
            else {
                for(const closeTaskField of this.closeTaskFields){
                    this.clearAbstractControl(this.taskFormData.controls[closeTaskField]);
                    if(this.task) delete this.task[closeTaskField as keyof WaterTask];
                }
            }
        } catch (err) {}
    }

    /**
     * Clears the value, marks the control as pristine, and marks the control as untouched.
     * 
     * @param control - The abstract control to be cleared.
     */
    clearAbstractControl(control: AbstractControl): void {
        control.setValue(null);
        control.markAsPristine();
        control.markAsUntouched();
    }

    /**
     * Requests services for the task.
     * @returns {Promise<void>} A promise that resolves when the services are requested.
     */
    async requestServices(): Promise<void> {
        try {
            const task: WaterTask = await this.utilsService.openCommonServicesInformDialog(this.task!);
            const keys = Object.keys(task);
            for(const key of keys){
                if(task[key as keyof WaterTask]){
                    const value = task[key as keyof WaterTask];
                    if(Array.isArray(value)) { 
                        this.taskFormData.controls[key].setValue(value.map((v:any) => v.value));
                    }
                    else this.taskFormData.controls[key].setValue(value);
                }
            }
        } catch (err) {}
    }

    /**
     * Checks if the priority is set to HIBERNATE and prompts the user to select an end date and time for hibernation.
     * If the user selects both a date and time, updates the task form data with the hibernation details.
     * 
     * @param priority - The priority status of the task.
     */
    async checkHibernation(priority: any): Promise<void> {
        if (priority == priority_status.HIBERNATE) {
            const endHibernationDate = await this.utilsService.openDateSelectorDialog(
                'Seleccione fecha fin de hibernacion'
            );
            const endHibernationTime = await this.utilsService.openTimeSelectorDialog(
                'Seleccione hora de fin de hibernacion'
            );
            if (endHibernationDate && endHibernationTime) {
                const momentDate: moment.Moment = moment(endHibernationDate);
                momentDate.set({
                    hour: endHibernationTime.getHours(),
                    minute: endHibernationTime.getMinutes(),
                    second: 0,
                });
                this.taskFormData.controls['hibernacion'].setValue(true);
                this.taskFormData.controls['end_hibernation_date'].setValue(
                    momentDate.toDate()
                );
                this.taskFormData.controls['end_hibernation_priority'].setValue(
                    this.task!.prioridad || priority_status.LOW
                );
            }
        }
    }

    /**
     * Handles the change event of the geolocation.
     * Retrieves ITAC data and updates the route data based on the provided code.
     * @param code - The geolocation code.
     */
    async onGeolocationChange(code: any): Promise<void> {
        if (code) {
            const itacs = await this._apiService.getItacs([['codigo_itac', '==', code]]);
            this.updateItacData(itacs);
            if (code.includes('-')) code = code.split('-')[0].trim();
            
            const companyId = localStorage.getItem('company');
            const managerId = localStorage.getItem('manager');
            const waterRoutes = await this._apiService.getWaterRoutes([
                ['codigo_ruta', '==', code],
                ['manager', '==', managerId],
                ['company', '==', companyId],
            ]);
            this.updateRouteData(waterRoutes);
        }
    }

    /**
     * Updates the ITAC data in the form.
     * @param itacs - An array of ITAC objects.
     */
    updateItacData(itacs: Itac[]): void {
        if (itacs && itacs.length > 0) {
            const itac = itacs[0];
            if (itac.zona) this.taskFormData.controls['zona'].setValue(itac.zona);
            
            if (itac.geolocalizacion) {
                this.taskFormData.controls['pendent_location'].setValue(false);
                this.taskFormData.controls['geolocalizacion'].setValue(itac.geolocalizacion);
                this.taskFormData.controls['codigo_de_localizacion'].setValue(itac.geolocalizacion);
                const url = this.utilsService.getGeolocationUrl(itac.geolocalizacion);
                this.taskFormData.controls['url_geolocalizacion'].setValue(url);
            }
        }
    }

    /**
     * Updates the route data based on the provided water routes.
     * @param waterRoutes - An array of water routes.
     */
    updateRouteData(waterRoutes: WaterRoute[]): void {
        if (waterRoutes && waterRoutes.length > 0) {
            const waterRoute = waterRoutes[0];
            this.taskFormData.controls['zona'].setValue(waterRoute.barrio);
            this.taskFormData.controls['ruta'].setValue(waterRoute.ruta);
            this.taskFormData.controls['tipoRadio'].setValue(waterRoute.radio_portal);
            const day = this.utilsService.getDayOfZona(waterRoute.barrio!);
            this.taskFormData.controls['bloque'].setValue(day);
        }
    }

    /**
     * Saves the form data by updating the task object with the values from the form controls.
     * If the value is a number, it is directly assigned to the corresponding property of the task object.
     * If the value is a moment object, it is converted to a Date object before assigning it to the task object.
     * If the value is an array, it is converted to an object list using the utilsService.
     * If the value is a string, it is converted to an object using the utilsService and assigned to the task object.
     * Finally, the task object is updated with the last modification user ID and the current date and time.
     */
    saveFormData(): void {
        const keys = Object.keys(this.taskFormData.controls);
        for (let key of keys) {
            let value = this.taskFormData.controls[key].value; 
            if (typeof value === 'number') this.task![key as keyof WaterTask] = value as any;
            else if (typeof value === 'boolean') this.task![key as keyof WaterTask] = value as any;
            else if (value) {
                if (moment.isMoment(value)) {
                    if (value && value.year() < 1975) value = null;
                    else value = value.toDate();
                }
                else if (key === 'OBSERVADV' || key === 'servicios' || key === 'suministros') {
                    value = this.utilsService.getObjectList(value);
                }  
                else if (key === 'MENSAJE_LIBRE') value = [this.utilsService.getObject(value)];
                
                if(value === 'null') value = null;
                this.task![key as keyof WaterTask] = value;
            }
        }
        this.task!.ultima_modificacion = this._apiService.getLoggedUserId();
        this.task!.date_time_modified = new Date();
    }

    /**
     * Shows or hides the loading spinner based on the provided state.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean): void {
        this.loading = state;
        if (state) {
            this.spinner.show('taskSpinner', { type: this.utilsService.getRandomNgxSpinnerType() });
        } else this.spinner.hide('taskSpinner');
    }

    /**
     * Retrieves data and performs necessary actions.
     * Saves form data and closes the task assign dialog.
     */
    async retrieveData(): Promise<void> {
        this.saveFormData();
        this.utilsService.closeTaskAssignDialog(this.task!);
    }

    /**
     * Marks the task as reviewed when the MatSlideToggleChange event is triggered.
     * If the event is checked, it sets the value of 'last_modification_android' control to true.
     * 
     * @param event - The MatSlideToggleChange event object.
     */
    checkAsReviewed(event: MatSlideToggleChange): void {
        if (event.checked) this.taskFormData.controls['last_modification_android'].setValue(false);
        else this.taskFormData.controls['last_modification_android'].setValue(null);
    }

    /**
     * Handles the change event for the pendent location toggle.
     * 
     * @param event - The change event emitted by the MatSlideToggle.
     * 
     * When the toggle is checked, sets the 'pendent_location' control value to false.
     * When the toggle is unchecked, sets the 'pendent_location' control value to null.
     */
    pendentLocationChange(event: MatSlideToggleChange): void {
        if (event.checked) this.taskFormData.controls['pendent_location'].setValue(true);
        else this.taskFormData.controls['pendent_location'].setValue(null);
    }

    deassignOperatorToTasks(event: MatSlideToggleChange): void { 
        if (event.checked) this.taskFormData.controls['OPERARIO'].setValue([]);
        else this.taskFormData.controls['OPERARIO'].setValue(null);
    }

    /**
     * Marks the task as reviewed when the MatSlideToggleChange event is triggered.
     * If the event is checked, it sets the value of 'last_modification_android' control to true.
     * 
     * @param event - The MatSlideToggleChange event object.
     */
    deleteDates(event: MatSlideToggleChange): void {
        if (event.checked) { 
            const date = moment('1970-01-01');
            this.taskFormData.controls['fecha_hora_cita'].setValue(date);
            this.taskFormData.controls['fecha_hora_cita_end'].setValue(date);
            this.taskFormData.controls['nuevo_citas'].setValue('null');
            this.taskFormData.controls['cita_pendiente'].setValue(false);
        }
        else { 
            this.taskFormData.controls['fecha_hora_cita'].setValue(null);
            this.taskFormData.controls['fecha_hora_cita_end'].setValue(null);
            this.taskFormData.controls['nuevo_citas'].setValue(null);
            this.taskFormData.controls['cita_pendiente'].setValue(null);
        }
    }
}
