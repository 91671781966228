/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { saveAs as importedSaveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class FileSaverService {
    constructor(private http: HttpClient, private _utilsService: UtilsService) {}

    public startDownloadFile(url: string): Promise<Blob> {
        return new Promise<Blob>(async (resolve, reject) => {
            try {
                const blob = await firstValueFrom(this.http.get(url, { responseType: 'blob' }));
                if (blob) resolve(blob);
            } catch (err) {
                console.log('============= url not found =============');
                console.log(url);
                console.log('============= err =============');
                console.log(err);
            }
            reject();
        });
    }

    async getBase64FromUrl(url: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const blob = await this.startDownloadFile(url);
                const photoBase64 = await this._utilsService.convertBlobToBase64(blob);
                if (photoBase64) resolve(photoBase64);
            } catch (err) {}
            reject();
        });
    }

    async downloadFile(url: string, filename: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const blob = await this.startDownloadFile(url);
                if (blob) {
                    importedSaveAs(blob, filename);
                    resolve(true);
                } else resolve(false);
            } catch (err) {}
            reject();
        });
    }
    async downloadFileAndGetBlob(url: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const blob = await this.startDownloadFile(url);
                if (blob) resolve(blob);
            } catch (err) {}
            reject();
        });
    }
}
