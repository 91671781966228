<mat-dialog-content>
    <div [formGroup]="taskFormData" class="animate__animated animate__fadeIn animate__slow">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field style="width: 150px" appearance="fill">
                                    <mat-label>Fecha Emisión</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_FECEMISIO"
                                        formControlName="FECEMISIO"
                                        placeholder="Fecha Emisión"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker_FECEMISIO"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #picker_FECEMISIO></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field style="width: 140px" appearance="fill">
                                    <mat-label>Fecha Cierre</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_FECH_CIERRE"
                                        formControlName="FECH_CIERRE"
                                        placeholder="Fecha Cierre"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker_FECH_CIERRE"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #picker_FECH_CIERRE></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field style="width: 140px" appearance="fill">
                                    <mat-label>Fecha Información</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_fech_informacionnew"
                                        formControlName="fech_informacionnew"
                                        placeholder="Fecha Información"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker_fech_informacionnew"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #picker_fech_informacionnew></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Resultado</mat-label>
                                    <mat-select formControlName="resultado">
                                        <mat-option
                                            *ngFor="let option of results"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Tarifa</mat-label>
                                    <input
                                        formControlName="TARIFA"
                                        matInput
                                        type="text"
                                        placeholder="Tarifa"
                                    />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Actividad</mat-label>
                                    <input
                                        formControlName="ACTIVI"
                                        matInput
                                        type="text"
                                        placeholder="Actividad"
                                    />
                                </mat-form-field>

                                <mat-form-field style="max-width: 160px" appearance="fill">
                                    <mat-label>Propiedad</mat-label>
                                    <input
                                        formControlName="PROPIEDAD"
                                        matInput
                                        type="text"
                                        placeholder="Propiedad"
                                    />
                                </mat-form-field>

                                <mat-form-field style="max-width: 160px" appearance="fill">
                                    <mat-label>Pueblo</mat-label>
                                    <input
                                        formControlName="MUNICIPIO"
                                        matInput
                                        type="text"
                                        placeholder="Pueblo"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field style="flex: 3" appearance="fill">
                                    <mat-label>Calle</mat-label>
                                    <input
                                        formControlName="CALLE"
                                        matInput
                                        type="text"
                                        placeholder="Calle"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>Número</mat-label>
                                    <input
                                        formControlName="NUME"
                                        matInput
                                        type="text"
                                        placeholder="Número"
                                        maxlength="3"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>BIS</mat-label>
                                    <input
                                        formControlName="BIS"
                                        matInput
                                        type="text"
                                        placeholder="BIS"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>ESCALERA</mat-label>
                                    <input
                                        formControlName="escalera"
                                        matInput
                                        type="text"
                                        placeholder="ESCALERA"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>Piso</mat-label>
                                    <input
                                        formControlName="PISO"
                                        matInput
                                        type="text"
                                        placeholder="Piso"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>Mano</mat-label>
                                    <input
                                        formControlName="MANO"
                                        matInput
                                        type="text"
                                        placeholder="Mano"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field style="flex: 5" appearance="fill">
                                    <mat-label>Comentarios</mat-label>
                                    <input
                                        formControlName="COMENTARIOS"
                                        matInput
                                        type="text"
                                        placeholder="Comentarios"
                                    />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>C.Comunero</mat-label>
                                    <input
                                        formControlName="C_COMUNERO"
                                        matInput
                                        type="text"
                                        placeholder="C.Comunero"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field style="width: 160px" appearance="fill">
                                    <mat-label>Marca</mat-label>
                                    <mat-select formControlName="MARCA">
                                        <mat-option *ngFor="let option of marks" [value]="option">{{
                                            option
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Calibre</mat-label>
                                    <input
                                        formControlName="CALIBRE"
                                        matInput
                                        type="text"
                                        placeholder="Calibre"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Longitud</mat-label>
                                    <input
                                        formControlName="LARGO"
                                        matInput
                                        type="text"
                                        placeholder="Longitud"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Dígitos</mat-label>
                                    <input
                                        formControlName="RUEDAS"
                                        matInput
                                        type="text"
                                        placeholder="Dígitos"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>Tipo Fluido</mat-label>
                                    <input
                                        formControlName="TIPOFLUIDO"
                                        matInput
                                        type="text"
                                        placeholder="Tipo Fluido"
                                    />
                                </mat-form-field>
                                <mat-form-field style="max-width: 100px" appearance="fill">
                                    <mat-label>Radio</mat-label>
                                    <input
                                        formControlName="tipoRadio"
                                        matInput
                                        type="text"
                                        placeholder="Radio"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Clase Contador</mat-label>
                                    <input
                                        formControlName="TIPO"
                                        matInput
                                        type="text"
                                        placeholder="Clase Contador"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Emplazamiento</mat-label>
                                    <input
                                        formControlName="EMPLAZA"
                                        matInput
                                        type="text"
                                        placeholder="Emplazamiento"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Orden de ruta</mat-label>
                                    <input
                                        formControlName="ORD_RUT"
                                        matInput
                                        type="text"
                                        placeholder="Orden de ruta"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Causa Origen</mat-label>
                                    <mat-select formControlName="causa_origen">
                                        <mat-option
                                            *ngFor="let option of causes"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Accion Ordenada</mat-label>
                                    <input
                                        formControlName="accion_ordenada"
                                        matInput
                                        type="text"
                                        placeholder="Accion Ordenada"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill" style="flex: 5">
                                    <mat-label>Datos específicos</mat-label>
                                    <input
                                        formControlName="observaciones"
                                        matInput
                                        type="text"
                                        placeholder="Datos específicos"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill" style="flex: 1">
                                    <mat-label>Código de acción ordenada</mat-label>
                                    <input
                                        formControlName="tipo_tarea"
                                        matInput
                                        type="text"
                                        placeholder="Código de acción ordenada"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Radio</mat-label>
                                    <input
                                        formControlName="TIPORADIO_DEVUELTO"
                                        matInput
                                        type="text"
                                        placeholder="Radio"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 150px" appearance="fill">
                                    <mat-label>Fecha Ejecución</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_execution_date"
                                        formControlName="F_INST"
                                        placeholder="Fecha Ejecución"
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker_execution_date"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #picker_execution_date></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field style="width: 130px" appearance="fill">
                                    <mat-label>Lectura Instalado</mat-label>
                                    <input
                                        formControlName="LECTURA_CONTADOR_NUEVO"
                                        matInput
                                        type="number"
                                        placeholder="Lectura Instalado"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field style="width: 160px" appearance="fill">
                                    <mat-label>Marca</mat-label>
                                    <mat-select formControlName="MARCADV">
                                        <mat-option *ngFor="let option of marks" [value]="option">{{
                                            option
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Calibre</mat-label>
                                    <input
                                        formControlName="CALIBREDV"
                                        matInput
                                        type="text"
                                        placeholder="Calibre"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Longitud</mat-label>
                                    <input
                                        formControlName="LONGDV"
                                        matInput
                                        type="text"
                                        placeholder="Longitud"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 60px" appearance="fill">
                                    <mat-label>Dígitos</mat-label>
                                    <input
                                        formControlName="RUEDASDV"
                                        matInput
                                        type="text"
                                        placeholder="Dígitos"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 80px" appearance="fill">
                                    <mat-label>Tipo Fluido</mat-label>
                                    <input
                                        formControlName="TIPOFLUIDO_DEVUELTO"
                                        matInput
                                        type="text"
                                        placeholder="Tipo Fluido"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Clase Contador</mat-label>
                                    <input
                                        formControlName="TIPO_DEVUELTO"
                                        matInput
                                        type="text"
                                        placeholder="Clase Contador"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Emplazamiento</mat-label>
                                    <mat-select formControlName="EMPLAZADV">
                                        <mat-option
                                            *ngFor="let option of emplacements"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Resto Emplazamiento</mat-label>
                                    <input
                                        formControlName="RESTO_EM"
                                        matInput
                                        type="text"
                                        placeholder="Resto Emplazamiento"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field style="width: 350px" appearance="fill">
                                    <mat-label>Causa Destino</mat-label>
                                    <mat-select formControlName="AREALIZARDV">
                                        <mat-option
                                            *ngFor="let option of causes"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 350px" appearance="fill">
                                    <mat-label>Información interna</mat-label>
                                    <input
                                        formControlName="intervencidv"
                                        matInput
                                        type="text"
                                        placeholder="Información interna"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field style="width: 350px" appearance="fill">
                                    <mat-label>Código Observaciones</mat-label>
                                    <mat-select formControlName="OBSERVADV" multiple>
                                        <mat-option
                                            *ngFor="let option of observations"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 350px" appearance="fill">
                                    <mat-label>Mensaje Libre</mat-label>
                                    <input
                                        formControlName="MENSAJE_LIBRE"
                                        matInput
                                        type="text"
                                        placeholder="Mensaje Libre"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>TIPO ORDEN</mat-label>
                                    <mat-select formControlName="TIPORDEN">
                                        <mat-option
                                            *ngFor="let option of [0, 1, 2]"
                                            [value]="option"
                                            >{{ option | taskOrder }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>ANOMALIA</mat-label>
                                    <mat-select formControlName="ANOMALIA">
                                        <mat-option
                                            *ngFor="let option of anomalies"
                                            [value]="option"
                                            >{{ option  }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Incidencia en android</mat-label>
                                    <mat-select formControlName="incidence">
                                        <mat-option
                                            *ngFor="let option of [false, true]"
                                            [value]="option"
                                            >{{ option | incidence }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Acceso</mat-label>
                                    <input
                                        formControlName="ACCESO"
                                        matInput
                                        type="text"
                                        placeholder="Acceso"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Sector P</mat-label>
                                    <mat-select formControlName="zona">
                                        <mat-option *ngFor="let option of zones" [value]="option">{{
                                            option
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Bloque</mat-label>
                                    <input
                                        formControlName="bloque"
                                        matInput
                                        type="text"
                                        placeholder="Bloque"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Código de emplazamiento</mat-label>
                                    <input
                                        formControlName="codigo_de_geolocalizacion"
                                        matInput
                                        type="text"
                                        placeholder="Código de emplazamiento"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Ruta</mat-label>
                                    <input
                                        formControlName="ruta"
                                        matInput
                                        type="text"
                                        placeholder="Ruta"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>marcaR</mat-label>
                                    <input
                                        formControlName="marcaR"
                                        matInput
                                        type="text"
                                        placeholder="marcaR"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>URL Google Maps</mat-label>
                                    <input
                                        formControlName="url_geolocalizacion"
                                        matInput
                                        type="text"
                                        placeholder="URL Google Maps"
                                        readonly
                                    />
                                    <button
                                        *ngIf="taskFormData.controls['url_geolocalizacion'].value"
                                        matTooltip="Abrir ubicación de tarea en Google Maps"
                                        matSuffix
                                        mat-icon-button
                                        aria-label="Open google maps"
                                    >
                                        <a
                                            [href]="taskFormData.controls['url_geolocalizacion'].value"
                                            target="_blank"
                                        >
                                            <img
                                                src="assets/img/google_maps_icon.svg"
                                                width="20"
                                                alt="Open google maps"
                                            />
                                        </a>
                                    </button>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Prioridad</mat-label>
                                    <mat-select formControlName="prioridad">
                                        <mat-option
                                            *ngFor="let option of [0, 1, 2, 3]"
                                            [value]="option"
                                            >{{ option | priority }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Estado</mat-label>
                                    <mat-select formControlName="status_tarea">
                                        <mat-option
                                            *ngFor="let option of utilsService.getTaskStatuses()"
                                            [value]="option"
                                            >{{ option | taskStatus }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <button
                                    *ngIf="taskFormData.controls['status_tarea'].value === closedStatus"
                                    style="margin: 5px;"
                                    matTooltip="Modificar servicios de cierre de tareas"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Modify services"
                                    (click)="requestServices()"
                                >
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Planificación</mat-label>
                                    <mat-select formControlName="planning">
                                        <mat-option
                                            *ngFor="let option of plannings"
                                            [matTooltip]="option"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle de planificación</mat-label>
                                    <mat-select formControlName="planning_details">
                                        <mat-option
                                            *ngFor="let option of planningDetails"
                                            [matTooltip]="option"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-slide-toggle class="custom-slider" color="primary" (change)="checkAsReviewed($event)">Marcar como revisadas</mat-slide-toggle>
                                <mat-slide-toggle class="custom-slider" color="warn" (change)="deassignOperatorToTasks($event)">Desasignar operarios</mat-slide-toggle>
                                <mat-slide-toggle class="custom-slider" color="warn" (change)="pendentLocationChange($event)">Mal localizada</mat-slide-toggle>
                                <mat-slide-toggle class="date-slider" color="primary" (change)="deleteDates($event)">Eliminar citas</mat-slide-toggle>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div style="height: 82px"></div>
    </div>

    <mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancelar</button>
            <button mat-button cdkFocusInitial (click)="retrieveData()">Aceptar</button>
        </mat-dialog-actions>

        <ngx-spinner
            [bdColor]="'rgba(0, 0, 0, 0.2)'"
            size="large"
            color="#368DCE"
            type="ball-scale-multiple"
            [fullScreen]="false"
            name="taskSpinner"
        >
        </ngx-spinner></mat-dialog-content
></mat-dialog-content>
