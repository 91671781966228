import { FormControl, FormGroup } from '@angular/forms';

import { MyLatLng } from './lat-lng';
import { MiRutaUser } from './mi-ruta-user';
import { task_status, myLatLngFromString, priorityFromString } from './water-task';

export interface Itac {
    ID?: number; //useless only for displaying info
    acceso?: string;
    acceso_ubicacion_acceso?: string;
    acceso_ubicacion_nota?: string;
    acceso_ubicacion_ubicacion?: string;
    audio_detalle?: string;
    audio_detalle_server_path?: string;
    carnet_firmante?: string;
    codigo_itac?: string;
    colector?: string;
    correo_administracion?: string;
    correo_encargado?: string;
    correo_presidente?: string;
    date_time_modified?: Date;
    company?: number;
    desague?: string;
    descripcion?: string;
    descripcion_foto_1?: string;
    descripcion_foto_2?: string;
    descripcion_foto_3?: string;
    descripcion_foto_4?: string;
    descripcion_foto_5?: string;
    descripcion_foto_6?: string;
    descripcion_foto_7?: string;
    descripcion_foto_8?: string;
    direccion_oficina?: string;
    equipo?: number;
    espacio_para_trabajar?: string;
    estado_de_conservacion_nota?: string;
    estado_de_tuberias_nota?: string;
    estado_de_valvulas_nota?: string;
    extra_acceso_ubicacion?: string;
    extras_desague?: string;
    extras_iluminacion?: string;
    extras_llaves?: string;
    extras_llaves_utilidad_de_llave_maestra?: string;
    extras_valvula_antiretorno?: string;
    extras_valvula_entrada?: string;
    extras_valvula_general?: string;
    extras_valvula_salida?: string;
    fecha_cierre?: Date;
    fecha_ejecucion?: Date;
    fecha_hora_cita?: Date;
    fecha_hora_cita_end?: Date;
    fecha_importacion?: Date;
    fecha_informe?: Date;
    foto_1?: string;
    foto_2?: string;
    foto_3?: string;
    foto_4?: string;
    foto_5?: string;
    foto_6?: string;
    foto_7?: string;
    foto_8?: string;
    foto_9?: string;
    thumbnail_type?: string;
    thumbnail?: string;
    thumbnail_server_path?: string;
    foto_1_server_path?: string;
    foto_2_server_path?: string;
    foto_3_server_path?: string;
    foto_4_server_path?: string;
    foto_5_server_path?: string;
    foto_6_server_path?: string;
    foto_7_server_path?: string;
    foto_8_server_path?: string;
    foto_9_server_path?: string; //photo?: sign
    geolocalizacion?: MyLatLng;
    gestor?: number;
    hibernacion?: boolean;
    id?: number;
    iluminacion?: string;
    itac?: string; //ITAC'Direction?:
    llaves_nota?: string;
    nombre_empresa_administracion?: string;
    nombre_encargado?: string;
    nombre_firmante?: string;
    nombre_presidente?: string;
    nombre_responsable_administracion?: string;
    nuevo_citas?: string;
    operario?: MiRutaUser[];
    prioridad?: number;
    puntos_agua_con_contador?: number;
    puntos_agua_con_contador_mas_tarea?: number;
    puntos_agua_total?: number;
    puntuacion?: number;
    puntuacion_seccion1?: number;
    puntuacion_seccion2?: number;
    puntuacion_seccion3?: number;
    puntuacion_seccion4?: number;
    puntuacion_seccion5?: number;
    siempre_abierto?: string;
    status_itac?: number;
    telefono_fijo_administracion?: string;
    telefono_fijo_encargado?: string;
    telefono_fijo_presidente?: string;
    telefono_movil_administracion?: string;
    telefono_movil_encargado?: string;
    telefono_movil_presidente?: string;
    telefonos_status?: string;
    tipo?: string;
    tipo_llave?: string;
    tuberias_de_entrada_contador?: string;
    tuberias_de_salida_contador?: string;
    tubo_de_alimentacion?: string;
    ultima_modificacion?: string;
    last_modification_android?: boolean;
    valvula_antiretorno?: string;
    valvula_entrada?: string;
    valvula_general?: string;
    valvula_salida?: string;
    vivienda_encargado?: string;
    vivienda_presidente?: string;
    zona?: string;
    upload_pendent?: boolean;
    cita_pendiente?: boolean;
    end_hibernation_date?: Date;
    end_hibernation_priority?: number;
    geolocation_details?: string;
    last_modification_operator_uid?: string;
    bloque?: string;
    planning?: string;
    planning_details?: string;
    planning_date_modified?: Date;
    pendent_location?: boolean;
}

export function getItacDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'SECTOR P',
        'DIRECCIÓN',
        'C.EMPLAZAMIENTO',
        'PLANIFICACIÓN',
        'PUNTUACIÓN DE ITAC',
        'ADMINISTRACIÓN',
        'PRESIDENTE O COLABORADOR',
        'ENCARGADO O CONSERJE',
        'NOTA GLOBAL',
        'N PUNTOS DE AGUA TOTAL',
        'N PUNTOS DE AGUA CON CONTADOR',
        'N PUNTOS DE AGUA CONCONTADOR MÁS TAREA',
        'ACCESO',
        'LLAVES DE ACCESO',
        'ESPACIO PARA TRABAJAR',
        'DESAGÜE',
        'ILUMINACIÓN',
        'TUBO DE ALIMENTACIÓN',
        'COLECTOR',
        'TUBERÍAS DE ENTRADA',
        'TUBERÍAS DE SALIDA',
        'VÁLVULA GENERAL',
        'VÁLVULA DE ENTRADA',
        'VÁLVULA DE SALIDA',
        'VÁLVULA DE ANTIRETORNO',
        'EQUIPO',
        'OPERARIO',
        'PRIORIDAD',
        'MODIFICACIÓN',
    ];
    return displayedColumns;
}

export function getItacField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'SECTOR P':
            value = 'zona';
            break;
        case 'DIRECCIÓN':
            value = 'itac';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_itac';
            break;
        case 'PLANIFICACIÓN':
            value = 'planning';
            break;
        case 'PUNTUACIÓN DE ITAC':
            value = 'puntuacion';
            break;
        case 'ADMINISTRACIÓN':
            value = 'nombre_empresa_administracion';
            break;
        case 'PRESIDENTE O COLABORADOR':
            value = 'nombre_presidente';
            break;
        case 'ENCARGADO O CONSERJE':
            value = 'nombre_encargado';
            break;
        case 'NOTA GLOBAL':
            value = 'acceso';
            break;
        case 'N PUNTOS DE AGUA TOTAL':
            value = 'puntos_agua_total';
            break;
        case 'N PUNTOS DE AGUA CON CONTADOR':
            value = 'puntos_agua_con_contador';
            break;
        case 'N PUNTOS DE AGUA CONCONTADOR MÁS TAREA':
            value = 'puntos_agua_con_contador_mas_tarea';
            break;
        case 'ACCESO':
            value = 'extra_acceso_ubicacion';
            break;
        case 'LLAVES DE ACCESO':
            value = 'tipo_llave';
            break;
        case 'ESPACIO PARA TRABAJAR':
            value = 'espacio_para_trabajar';
            break;
        case 'DESAGÜE':
            value = 'desague';
            break;
        case 'ILUMINACIÓN':
            value = 'iluminacion';
            break;
        case 'TUBO DE ALIMENTACIÓN':
            value = 'tubo_de_alimentacion';
            break;
        case 'COLECTOR':
            value = 'colector';
            break;
        case 'TUBERÍAS DE ENTRADA':
            value = 'tuberias_de_entrada_contador';
            break;
        case 'TUBERÍAS DE SALIDA':
            value = 'tuberias_de_salida_contador';
            break;
        case 'VÁLVULA GENERAL':
            value = 'valvula_general';
            break;
        case 'VÁLVULA DE ENTRADA':
            value = 'valvula_entrada';
            break;
        case 'VÁLVULA DE SALIDA':
            value = 'valvula_salida';
            break;
        case 'VÁLVULA DE ANTIRETORNO':
            value = 'valvula_antiretorno';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'operario';
            break;
        case 'PRIORIDAD':
            value = 'prioridad';
            break;
        case 'GESTOR':
            value = 'gestor';
            break;
        case 'MODIFICACIÓN':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getItacFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'zona':
            value = 'SECTOR P';
            break;
        case 'itac':
            value = 'DIRECCIÓN';
            break;
        case 'codigo_itac':
            value = 'C.EMPLAZAMIENTO';
            break;
        case 'planning':
            value = 'PLANIFICACIÓN';
            break;
        case 'date_time_modified':
            value = 'MODIFICACIÓN';
            break;
        case 'puntuacion':
            value = 'PUNTUACIÓN DE ITAC';
            break;
        case 'nombre_empresa_administracion':
            value = 'ADMINISTRACIÓN';
            break;
        case 'nombre_presidente':
            value = 'PRESIDENTE O COLABORADOR';
            break;
        case 'nombre_encargado':
            value = 'ENCARGADO O CONSERJE';
            break;
        case 'acceso':
            value = 'NOTA GLOBAL';
            break;
        case 'puntos_agua_total':
            value = 'N PUNTOS DE AGUA TOTAL';
            break;
        case 'puntos_agua_con_contador':
            value = 'N PUNTOS DE AGUA CON CONTADOR';
            break;
        case 'puntos_agua_con_contador_mas_tarea':
            value = 'N PUNTOS DE AGUA CONCONTADOR MÁS TAREA';
            break;
        case 'extra_acceso_ubicacion':
            value = 'ACCESO';
            break;
        case 'tipo_llave':
            value = 'LLAVES DE ACCESO';
            break;
        case 'espacio_para_trabajar':
            value = 'ESPACIO PARA TRABAJAR';
            break;
        case 'desague':
            value = 'DESAGÜE';
            break;
        case 'iluminacion':
            value = 'ILUMINACIÓN';
            break;
        case 'tubo_de_alimentacion':
            value = 'TUBO DE ALIMENTACIÓN';
            break;
        case 'colector':
            value = 'COLECTOR';
            break;
        case 'company':
            value = 'EMPRESA';
            break;
        case 'tuberias_de_entrada_contador':
            value = 'TUBERÍAS DE ENTRADA';
            break;
        case 'tuberias_de_salida_contador':
            value = 'TUBERÍAS DE SALIDA';
            break;
        case 'valvula_general':
            value = 'VÁLVULA GENERAL';
            break;
        case 'valvula_entrada':
            value = 'VÁLVULA DE ENTRADA';
            break;
        case 'valvula_salida':
            value = 'VÁLVULA DE SALIDA';
            break;
        case 'valvula_antiretorno':
            value = 'VÁLVULA DE ANTIRETORNO';
            break;
        case 'operario':
            value = 'OPERARIO';
            break;
        case 'equipo':
            value = 'EQUIPO';
            break;
        case 'gestor':
            value = 'GESTOR';
            break;
    }
    return value;
}

export function getItacFormControls(): FormGroup {
    const itacFormData: FormGroup = new FormGroup({
        acceso: new FormControl(),
        acceso_ubicacion_acceso: new FormControl(),
        acceso_ubicacion_nota: new FormControl(),
        acceso_ubicacion_ubicacion: new FormControl(),
        audio_detalle: new FormControl(),
        audio_detalle_server_path: new FormControl(),
        carnet_firmante: new FormControl(),
        codigo_itac: new FormControl(),
        colector: new FormControl(),
        correo_administracion: new FormControl(),
        correo_encargado: new FormControl(),
        correo_presidente: new FormControl(),
        company: new FormControl(),
        date_time_modified: new FormControl(),
        desague: new FormControl(),
        descripcion: new FormControl(),
        descripcion_foto_1: new FormControl(),
        descripcion_foto_2: new FormControl(),
        descripcion_foto_3: new FormControl(),
        descripcion_foto_4: new FormControl(),
        descripcion_foto_5: new FormControl(),
        descripcion_foto_6: new FormControl(),
        descripcion_foto_7: new FormControl(),
        descripcion_foto_8: new FormControl(),
        direccion_oficina: new FormControl(),
        equipo: new FormControl(),
        espacio_para_trabajar: new FormControl(),
        estado_de_conservacion_nota: new FormControl(),
        estado_de_tuberias_nota: new FormControl(),
        estado_de_valvulas_nota: new FormControl(),
        extra_acceso_ubicacion: new FormControl(),
        extras_desague: new FormControl(),
        extras_iluminacion: new FormControl(),
        extras_llaves: new FormControl(),
        extras_llaves_utilidad_de_llave_maestra: new FormControl(),
        extras_valvula_antiretorno: new FormControl(),
        extras_valvula_entrada: new FormControl(),
        extras_valvula_general: new FormControl(),
        extras_valvula_salida: new FormControl(),
        fecha_cierre: new FormControl(),
        fecha_ejecucion: new FormControl(),
        fecha_hora_cita: new FormControl(),
        fecha_hora_cita_end: new FormControl(),
        fecha_importacion: new FormControl(),
        fecha_informe: new FormControl(),
        foto_1: new FormControl(),
        foto_2: new FormControl(),
        foto_3: new FormControl(),
        foto_4: new FormControl(),
        foto_5: new FormControl(),
        foto_6: new FormControl(),
        foto_7: new FormControl(),
        foto_8: new FormControl(),
        foto_9: new FormControl(),
        thumbnail_type: new FormControl(),
        thumbnail: new FormControl(),
        thumbnail_server_path: new FormControl(),
        foto_1_server_path: new FormControl(),
        foto_2_server_path: new FormControl(),
        foto_3_server_path: new FormControl(),
        foto_4_server_path: new FormControl(),
        foto_5_server_path: new FormControl(),
        foto_6_server_path: new FormControl(),
        foto_7_server_path: new FormControl(),
        foto_8_server_path: new FormControl(),
        foto_9_server_path: new FormControl(), //photo?: sign
        geolocalizacion: new FormControl(),
        gestor: new FormControl(),
        hibernacion: new FormControl(),
        id: new FormControl(),
        iluminacion: new FormControl(),
        itac: new FormControl(), //ITAC'Direction?: s
        llaves_nota: new FormControl(),
        nombre_empresa_administracion: new FormControl(),
        nombre_encargado: new FormControl(),
        nombre_firmante: new FormControl(),
        nombre_presidente: new FormControl(),
        nombre_responsable_administracion: new FormControl(),
        nuevo_citas: new FormControl(),
        operario: new FormControl(),
        prioridad: new FormControl(),
        puntos_agua_con_contador: new FormControl(),
        puntos_agua_con_contador_mas_tarea: new FormControl(),
        puntos_agua_total: new FormControl(),
        puntuacion: new FormControl(),
        puntuacion_seccion1: new FormControl(),
        puntuacion_seccion2: new FormControl(),
        puntuacion_seccion3: new FormControl(),
        puntuacion_seccion4: new FormControl(),
        puntuacion_seccion5: new FormControl(),
        siempre_abierto: new FormControl(),
        status_itac: new FormControl(),
        telefono_fijo_administracion: new FormControl(),
        telefono_fijo_encargado: new FormControl(),
        telefono_fijo_presidente: new FormControl(),
        telefono_movil_administracion: new FormControl(),
        telefono_movil_encargado: new FormControl(),
        telefono_movil_presidente: new FormControl(),
        telefonos_status: new FormControl(),
        tipo: new FormControl(),
        tipo_llave: new FormControl(),
        tuberias_de_entrada_contador: new FormControl(),
        tuberias_de_salida_contador: new FormControl(),
        tubo_de_alimentacion: new FormControl(),
        ultima_modificacion: new FormControl(),
        last_modification_android: new FormControl(),
        valvula_antiretorno: new FormControl(),
        valvula_entrada: new FormControl(),
        valvula_general: new FormControl(),
        valvula_salida: new FormControl(),
        vivienda_encargado: new FormControl(),
        vivienda_presidente: new FormControl(),
        zona: new FormControl(),
        upload_pendent: new FormControl(),
        cita_pendiente: new FormControl(),
        end_hibernation_date: new FormControl(),
        end_hibernation_priority: new FormControl(),
        geolocation_details: new FormControl(),
        last_modification_operator_uid: new FormControl(),
        bloque: new FormControl(),
        planning: new FormControl(),
        planning_details: new FormControl(),
        planning_date_modified: new FormControl(),
        pendent_location: new FormControl(),
    });
    return itacFormData;
}

export function getItacFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'zona':
            value = 'string';
            break;
        case 'itac':
            value = 'string';
            break;
        case 'codigo_itac':
            value = 'string';
            break;
        case 'planning':
            value = 'string';
            break;
        case 'planning_details':
            value = 'string';
            break;
        case 'planning_date_modified':
            value = 'Date';
            break;
        case 'pendent_location':
            value = 'boolean';
            break;
        case 'puntuacion':
            value = 'number';
            break;
        case 'nombre_empresa_administracion':
            value = 'string';
            break;
        case 'nombre_presidente':
            value = 'string';
            break;
        case 'nombre_encargado':
            value = 'string';
            break;
        case 'acceso':
            value = 'string';
            break;
        case 'puntos_agua_total':
            value = 'number';
            break;
        case 'puntos_agua_con_contador':
            value = 'number';
            break;
        case 'puntos_agua_con_contador_mas_tarea':
            value = 'number';
            break;
        case 'extra_acceso_ubicacion':
            value = 'string';
            break;
        case 'tipo_llave':
            value = 'string';
            break;
        case 'espacio_para_trabajar':
            value = 'string';
            break;
        case 'desague':
            value = 'string';
            break;
        case 'iluminacion':
            value = 'string';
            break;
        case 'tubo_de_alimentacion':
            value = 'string';
            break;
        case 'colector':
            value = 'string';
            break;
        case 'company':
            value = 'number';
            break;
        case 'tuberias_de_entrada_contador':
            value = 'string';
            break;
        case 'tuberias_de_salida_contador':
            value = 'string';
            break;
        case 'valvula_general':
            value = 'string';
            break;
        case 'valvula_entrada':
            value = 'string';
            break;
        case 'valvula_salida':
            value = 'string';
            break;
        case 'valvula_antiretorno':
            value = 'string';
            break;
        case 'operario':
            value = 'string';
            break;
        case 'gestor':
            value = 'number';
            break;
        case 'acceso_ubicacion_acceso':
            value = 'string';
            break;
        case 'acceso_ubicacion_nota':
            value = 'string';
            break;
        case 'acceso_ubicacion_ubicacion':
            value = 'string';
            break;
        case 'audio_detalle':
            value = 'string';
            break;
        case 'audio_detalle_server_path':
            value = 'string';
            break;
        case 'carnet_firmante':
            value = 'string';
            break;
        case 'codigo_itac':
            value = 'string';
            break;
        case 'colector':
            value = 'string';
            break;
        case 'correo_administracion':
            value = 'string';
            break;
        case 'correo_encargado':
            value = 'string';
            break;
        case 'correo_presidente':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'company':
            value = 'number';
            break;
        case 'desague':
            value = 'string';
            break;
        case 'descripcion':
            value = 'string';
            break;
        case 'descripcion_foto_1':
            value = 'string';
            break;
        case 'descripcion_foto_2':
            value = 'string';
            break;
        case 'descripcion_foto_3':
            value = 'string';
            break;
        case 'descripcion_foto_4':
            value = 'string';
            break;
        case 'descripcion_foto_5':
            value = 'string';
            break;
        case 'descripcion_foto_6':
            value = 'string';
            break;
        case 'descripcion_foto_7':
            value = 'string';
            break;
        case 'descripcion_foto_8':
            value = 'string';
            break;
        case 'direccion_oficina':
            value = 'string';
            break;
        case 'equipo':
            value = 'number';
            break;
        case 'espacio_para_trabajar':
            value = 'string';
            break;
        case 'estado_de_conservacion_nota':
            value = 'string';
            break;
        case 'estado_de_tuberias_nota':
            value = 'string';
            break;
        case 'estado_de_valvulas_nota':
            value = 'string';
            break;
        case 'extra_acceso_ubicacion':
            value = 'string';
            break;
        case 'extras_desague':
            value = 'string';
            break;
        case 'extras_iluminacion':
            value = 'string';
            break;
        case 'extras_llaves':
            value = 'string';
            break;
        case 'extras_llaves_utilidad_de_llave_maestra':
            value = 'string';
            break;
        case 'extras_valvula_antiretorno':
            value = 'string';
            break;
        case 'extras_valvula_entrada':
            value = 'string';
            break;
        case 'extras_valvula_general':
            value = 'string';
            break;
        case 'extras_valvula_salida':
            value = 'string';
            break;
        case 'fecha_cierre':
            value = 'Date';
            break;
        case 'fecha_ejecucion':
            value = 'Date';
            break;
        case 'fecha_hora_cita':
            value = 'Date';
            break;
        case 'fecha_hora_cita_end':
            value = 'Date';
            break;
        case 'fecha_importacion':
            value = 'Date';
            break;
        case 'fecha_informe':
            value = 'Date';
            break;
        case 'foto_1':
            value = 'string';
            break;
        case 'foto_2':
            value = 'string';
            break;
        case 'foto_3':
            value = 'string';
            break;
        case 'foto_4':
            value = 'string';
            break;
        case 'foto_5':
            value = 'string';
            break;
        case 'foto_6':
            value = 'string';
            break;
        case 'foto_7':
            value = 'string';
            break;
        case 'foto_8':
            value = 'string';
            break;
        case 'foto_9':
            value = 'string';
            break;
        case 'thumbnail':
            value = 'string';
            break;
        case 'thumbnail_server_path':
            value = 'string';
            break;
        case 'foto_1_server_path':
            value = 'string';
            break;
        case 'foto_2_server_path':
            value = 'string';
            break;
        case 'foto_3_server_path':
            value = 'string';
            break;
        case 'foto_4_server_path':
            value = 'string';
            break;
        case 'foto_5_server_path':
            value = 'string';
            break;
        case 'foto_6_server_path':
            value = 'string';
            break;
        case 'foto_7_server_path':
            value = 'string';
            break;
        case 'foto_8_server_path':
            value = 'string';
            break;
        case 'foto_9_server_path':
            value = 'string';
            break;
        case 'geolocalizacion':
            value = 'string';
            break;
        case 'hibernacion':
            value = 'boolean';
            break;
        case 'id':
            value = 'number';
            break;
        case 'iluminacion':
            value = 'string';
            break;
        case 'itac':
            value = 'string';
            break;
        case 'llaves_nota':
            break;
            value = 'string';
            break;
        case 'nombre_empresa_administracion':
            value = 'string';
            break;
        case 'nombre_encargado':
            value = 'string';
            break;
        case 'nombre_firmante':
            value = 'string';
            break;
        case 'nombre_presidente':
            value = 'string';
            break;
        case 'nombre_responsable_administracion':
            value = 'string';
            break;
        case 'nuevo_citas':
            value = 'string';
            break;
        case 'prioridad':
            value = 'number';
            break;
        case 'puntos_agua_con_contador':
            value = 'number';
            break;
        case 'puntos_agua_total':
            value = 'number';
            break;
        case 'puntuacion':
            value = 'number';
            break;
        case 'puntuacion_seccion1':
            value = 'number';
            break;
        case 'puntuacion_seccion2':
            value = 'number';
            break;
        case 'puntuacion_seccion3':
            value = 'number';
            break;
        case 'puntuacion_seccion4':
            value = 'number';
            break;
        case 'puntuacion_seccion5':
            value = 'number';
            break;
        case 'siempre_abierto':
            value = 'string';
            break;
        case 'status_itac':
            value = 'number';
            break;
        case 'telefono_fijo_administracion':
            value = 'string';
            break;
        case 'telefono_fijo_encargado':
            value = 'string';
            break;
        case 'telefono_fijo_presidente':
            value = 'string';
            break;
        case 'telefono_movil_administracion':
            value = 'string';
            break;
        case 'telefono_movil_encargado':
            value = 'string';
            break;
        case 'telefono_movil_presidente':
            value = 'string';
            break;
        case 'telefonos_status':
            value = 'string';
            break;
        case 'tipo':
            value = 'string';
            break;
        case 'tipo_llave':
            value = 'string';
            break;
        case 'tuberias_de_entrada_contador':
            value = 'string';
            break;
        case 'tuberias_de_salida_contador':
            value = 'string';
            break;
        case 'tubo_de_alimentacion':
            value = 'string';
            break;
        case 'ultima_modificacion':
            value = 'string';
            break;
        case 'last_modification_android':
            value = 'boolean';
            break;
        case 'vivienda_encargado':
            value = 'string';
            break;
        case 'vivienda_presidente':
            value = 'string';
            break;
        case 'upload_pendent':
            value = 'boolean';
            break;
        case 'cita_pendiente':
            value = 'boolean';
            break;
        case 'end_hibernation_date':
            value = 'Date';
            break;
        case 'end_hibernation_priority':
            value = 'number';
            break;
        case 'geolocation_details':
            value = 'string';
            break;
        case 'last_modification_operator_uid':
            value = 'string';
            break;
        case 'bloque':
            value = 'string';
            break;
    }
    return value;
}

export function getItacExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim()) {
        case 'SECTOR P':
            value = 'zona';
            break;
        case 'DIRECCIÓN':
            value = 'itac';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_itac';
            break;
        case 'PLANIFICACIÓN':
            value = 'planning';
            break;
        case 'PUNTUACIÓN DE ITAC':
            value = 'puntuacion';
            break;
        case 'ADMINISTRACIÓN':
            value = 'nombre_empresa_administracion';
            break;
        case 'PRESIDENTE O COLABORADOR':
            value = 'nombre_presidente';
            break;
        case 'ENCARGADO O CONSERJE':
            value = 'nombre_encargado';
            break;
        case 'NOTA GLOBAL':
            value = 'acceso';
            break;
        case 'N PUNTOS DE AGUA TOTAL':
            value = 'puntos_agua_total';
            break;
        case 'N PUNTOS DE AGUA CON CONTADOR':
            value = 'puntos_agua_con_contador';
            break;
        case 'N PUNTOS DE AGUA CONCONTADOR MÁS TAREA':
            value = 'puntos_agua_con_contador_mas_tarea';
            break;
        case 'ACCESO':
            value = 'extra_acceso_ubicacion';
            break;
        case 'LLAVES DE ACCESO':
            value = 'tipo_llave';
            break;
        case 'ESPACIO PARA TRABAJAR':
            value = 'espacio_para_trabajar';
            break;
        case 'DESAGÜE':
            value = 'desague';
            break;
        case 'ILUMINACIÓN':
            value = 'iluminacion';
            break;
        case 'TUBO DE ALIMENTACIÓN':
            value = 'tubo_de_alimentacion';
            break;
        case 'COLECTOR':
            value = 'colector';
            break;
        case 'EMPRESA':
            value = 'company';
            break;
        case 'TUBERÍAS DE ENTRADA':
            value = 'tuberias_de_entrada_contador';
            break;
        case 'TUBERÍAS DE SALIDA':
            value = 'tuberias_de_salida_contador';
            break;
        case 'VÁLVULA GENERAL':
            value = 'valvula_general';
            break;
        case 'VÁLVULA DE ENTRADA':
            value = 'valvula_entrada';
            break;
        case 'VÁLVULA DE SALIDA':
            value = 'valvula_salida';
            break;
        case 'VÁLVULA DE ANTIRETORNO':
            value = 'valvula_antiretorno';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'operario';
            break;
        case 'GESTOR':
            value = 'gestor';
            break;

        case 'acceso':
            value = 'acceso';
            break;
        case 'acceso_ubicacion_acceso':
            value = 'acceso_ubicacion_acceso';
            break;
        case 'acceso_ubicacion_nota':
            value = 'acceso_ubicacion_nota';
            break;
        case 'acceso_ubicacion_ubicacion':
            value = 'acceso_ubicacion_ubicacion';
            break;
        case 'audio_detalle':
            value = 'audio_detalle';
            break;
        case 'audio_detalle_server_path':
            value = 'audio_detalle_server_path';
            break;
        case 'carnet_firmante':
            value = 'carnet_firmante';
            break;
        case 'codigo_itac':
            value = 'codigo_itac';
            break;
        case 'colector':
            value = 'colector';
            break;
        case 'correo_administracion':
            value = 'correo_administracion';
            break;
        case 'correo_encargado':
            value = 'correo_encargado';
            break;
        case 'correo_presidente':
            value = 'correo_presidente';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'MODIFICACIÓN':
            value = 'date_time_modified';
            break;
        case 'company':
            value = 'company';
            break;
        case 'desague':
            value = 'desague';
            break;
        case 'descripcion':
            value = 'descripcion';
            break;
        case 'descripcion_foto_1':
            value = 'descripcion_foto_1';
            break;
        case 'descripcion_foto_2':
            value = 'descripcion_foto_2';
            break;
        case 'descripcion_foto_3':
            value = 'descripcion_foto_3';
            break;
        case 'descripcion_foto_4':
            value = 'descripcion_foto_4';
            break;
        case 'descripcion_foto_5':
            value = 'descripcion_foto_5';
            break;
        case 'descripcion_foto_6':
            value = 'descripcion_foto_6';
            break;
        case 'descripcion_foto_7':
            value = 'descripcion_foto_7';
            break;
        case 'descripcion_foto_8':
            value = 'descripcion_foto_8';
            break;
        case 'direccion_oficina':
            value = 'direccion_oficina';
            break;
        case 'equipo':
            value = 'equipo';
            break;
        case 'espacio_para_trabajar':
            value = 'espacio_para_trabajar';
            break;
        case 'estado_de_conservacion_nota':
            value = 'estado_de_conservacion_nota';
            break;
        case 'estado_de_tuberias_nota':
            value = 'estado_de_tuberias_nota';
            break;
        case 'estado_de_valvulas_nota':
            value = 'estado_de_valvulas_nota';
            break;
        case 'extra_acceso_ubicacion':
            value = 'extra_acceso_ubicacion';
            break;
        case 'extras_desague':
            value = 'extras_desague';
            break;
        case 'extras_iluminacion':
            value = 'extras_iluminacion';
            break;
        case 'extras_llaves':
            value = 'extras_llaves';
            break;
        case 'extras_llaves_utilidad_de_llave_maestra':
            value = 'extras_llaves_utilidad_de_llave_maestra';
            break;
        case 'extras_valvula_antiretorno':
            value = 'extras_valvula_antiretorno';
            break;
        case 'extras_valvula_entrada':
            value = 'extras_valvula_entrada';
            break;
        case 'extras_valvula_general':
            value = 'extras_valvula_general';
            break;
        case 'extras_valvula_salida':
            value = 'extras_valvula_salida';
            break;
        case 'fecha_cierre':
            value = 'fecha_cierre';
            break;
        case 'fecha_ejecucion':
            value = 'fecha_ejecucion';
            break;
        case 'fecha_hora_cita':
            value = 'fecha_hora_cita';
            break;
        case 'fecha_hora_cita_end':
            value = 'fecha_hora_cita_end';
            break;
        case 'fecha_importacion':
            value = 'fecha_importacion';
            break;
        case 'fecha_informe':
            value = 'fecha_informe';
            break;
        case 'foto_1':
            value = 'foto_1';
            break;
        case 'foto_2':
            value = 'foto_2';
            break;
        case 'foto_3':
            value = 'foto_3';
            break;
        case 'foto_4':
            value = 'foto_4';
            break;
        case 'foto_5':
            value = 'foto_5';
            break;
        case 'foto_6':
            value = 'foto_6';
            break;
        case 'foto_7':
            value = 'foto_7';
            break;
        case 'foto_8':
            value = 'foto_8';
            break;
        case 'foto_9':
            value = 'foto_9';
            break;
        case 'thumbnail':
            value = 'thumbnail';
            break;
        case 'thumbnail_server_path':
            value = 'thumbnail_server_path';
            break;
        case 'foto_1_server_path':
            value = 'foto_1_server_path';
            break;
        case 'foto_2_server_path':
            value = 'foto_2_server_path';
            break;
        case 'foto_3_server_path':
            value = 'foto_3_server_path';
            break;
        case 'foto_4_server_path':
            value = 'foto_4_server_path';
            break;
        case 'foto_5_server_path':
            value = 'foto_5_server_path';
            break;
        case 'foto_6_server_path':
            value = 'foto_6_server_path';
            break;
        case 'foto_7_server_path':
            value = 'foto_7_server_path';
            break;
        case 'foto_8_server_path':
            value = 'foto_8_server_path';
            break;
        case 'foto_9_server_path':
            value = 'foto_9_server_path';
            break;
        case 'geolocalizacion':
            value = 'geolocalizacion';
            break;
        case 'gestor':
            value = 'gestor';
            break;
        case 'hibernacion':
            value = 'hibernacion';
            break;
        case 'id':
            value = 'id';
            break;
        case 'iluminacion':
            value = 'iluminacion';
            break;
        case 'itac':
            value = 'itac';
            break;
        case 'llaves_nota':
            value = 'llaves_nota';
            break;
        case 'nombre_empresa_administracion':
            value = 'nombre_empresa_administracion';
            break;
        case 'nombre_encargado':
            value = 'nombre_encargado';
            break;
        case 'nombre_firmante':
            value = 'nombre_firmante';
            break;
        case 'nombre_presidente':
            value = 'nombre_presidente';
            break;
        case 'nombre_responsable_administracion':
            value = 'nombre_responsable_administracion';
            break;
        case 'nuevo_citas':
            value = 'nuevo_citas';
            break;
        case 'operario':
            value = 'operario';
            break;
        case 'prioridad':
            value = 'prioridad';
            break;
        case 'puntos_agua_con_contador':
            value = 'puntos_agua_con_contador';
            break;
        case 'puntos_agua_con_contador_mas_tarea':
            value = 'puntos_agua_con_contador_mas_tarea';
            break;
        case 'puntos_agua_total':
            value = 'puntos_agua_total';
            break;
        case 'puntuacion':
            value = 'puntuacion';
            break;
        case 'puntuacion_seccion1':
            value = 'puntuacion_seccion1';
            break;
        case 'puntuacion_seccion2':
            value = 'puntuacion_seccion2';
            break;
        case 'puntuacion_seccion3':
            value = 'puntuacion_seccion3';
            break;
        case 'puntuacion_seccion4':
            value = 'puntuacion_seccion4';
            break;
        case 'puntuacion_seccion5':
            value = 'puntuacion_seccion5';
            break;
        case 'siempre_abierto':
            value = 'siempre_abierto';
            break;
        case 'status_itac':
            value = 'status_itac';
            break;
        case 'telefono_fijo_administracion':
            value = 'telefono_fijo_administracion';
            break;
        case 'telefono_fijo_encargado':
            value = 'telefono_fijo_encargado';
            break;
        case 'telefono_fijo_presidente':
            value = 'telefono_fijo_presidente';
            break;
        case 'telefono_movil_administracion':
            value = 'telefono_movil_administracion';
            break;
        case 'telefono_movil_encargado':
            value = 'telefono_movil_encargado';
            break;
        case 'telefono_movil_presidente':
            value = 'telefono_movil_presidente';
            break;
        case 'telefonos_status':
            value = 'telefonos_status';
            break;
        case 'tipo':
            value = 'tipo';
            break;
        case 'tipo_llave':
            value = 'tipo_llave';
            break;
        case 'tuberias_de_entrada_contador':
            value = 'tuberias_de_entrada_contador';
            break;
        case 'tuberias_de_salida_contador':
            value = 'tuberias_de_salida_contador';
            break;
        case 'tubo_de_alimentacion':
            value = 'tubo_de_alimentacion';
            break;
        case 'ultima_modificacion':
            value = 'ultima_modificacion';
            break;
        case 'last_modification_android':
            value = 'last_modification_android';
            break;
        case 'valvula_antiretorno':
            value = 'valvula_antiretorno';
            break;
        case 'valvula_entrada':
            value = 'valvula_entrada';
            break;
        case 'valvula_general':
            value = 'valvula_general';
            break;
        case 'valvula_salida':
            value = 'valvula_salida';
            break;
        case 'vivienda_encargado':
            value = 'vivienda_encargado';
            break;
        case 'vivienda_presidente':
            value = 'vivienda_presidente';
            break;
        case 'zona':
            value = 'zona';
            break;
        case 'upload_pendent':
            value = 'upload_pendent';
            break;
        case 'cita_pendiente':
            value = 'cita_pendiente';
            break;
        case 'end_hibernation_date':
            value = 'end_hibernation_date';
            break;
        case 'end_hibernation_priority':
            value = 'end_hibernation_priority';
            break;
        case 'geolocation_details':
            value = 'geolocation_details';
            break;
        case 'last_modification_operator_uid':
            value = 'last_modification_operator_uid';
            break;
        case 'bloque':
            value = 'bloque';
            break;
    }
    return value;
}

export function getItacExcelExportColumns(): string[] {
    const columns = [
        'SECTOR P',
        'DIRECCIÓN',
        'C.EMPLAZAMIENTO',
        'PLANIFICACIÓN',
        'PUNTUACIÓN DE ITAC',
        'ADMINISTRACIÓN',
        'PRESIDENTE O COLABORADOR',
        'ENCARGADO O CONSERJE',
        'NOTA GLOBAL',
        'N PUNTOS DE AGUA TOTAL',
        'N PUNTOS DE AGUA CON CONTADOR',
        'N PUNTOS DE AGUA CONCONTADOR MÁS TAREA',
        'ACCESO',
        'LLAVES DE ACCESO',
        'ESPACIO PARA TRABAJAR',
        'DESAGÜE',
        'ILUMINACIÓN',
        'TUBO DE ALIMENTACIÓN',
        'COLECTOR',
        'TUBERÍAS DE ENTRADA',
        'TUBERÍAS DE SALIDA',
        'VÁLVULA GENERAL',
        'VÁLVULA DE ENTRADA',
        'VÁLVULA DE SALIDA',
        'VÁLVULA DE ANTIRETORNO',
        'EQUIPO',
        'OPERARIO',
        'GESTOR',
    ];
    return columns;
}

export function isItacFileField(field_name: string): boolean {
    if (field_name == 'foto_1') {
        return true;
    }
    if (field_name == 'foto_2') {
        return true;
    }
    if (field_name == 'foto_3') {
        return true;
    }
    if (field_name == 'foto_4') {
        return true;
    }
    if (field_name == 'foto_5') {
        return true;
    }
    if (field_name == 'foto_6') {
        return true;
    }
    if (field_name == 'foto_7') {
        return true;
    }
    if (field_name == 'foto_8') {
        return true;
    }
    if (field_name == 'foto_9') {
        return true;
    }
    if (field_name == 'thumbnail') {
        return true;
    }
    if (field_name == 'thumbnail_server_path') {
        return true;
    }
    if (field_name == 'foto_1_server_path') {
        return true;
    }
    if (field_name == 'foto_2_server_path') {
        return true;
    }
    if (field_name == 'foto_3_server_path') {
        return true;
    }
    if (field_name == 'foto_4_server_path') {
        return true;
    }
    if (field_name == 'foto_5_server_path') {
        return true;
    }
    if (field_name == 'foto_6_server_path') {
        return true;
    }
    if (field_name == 'foto_7_server_path') {
        return true;
    }
    if (field_name == 'foto_8_server_path') {
        return true;
    }
    if (field_name == 'foto_9_server_path') {
        return true;
    }
    if (field_name == 'audio_detalle') {
        return true;
    }
    if (field_name == 'audio_detalle_server_path') {
        return true;
    }
    return false;
}

export function getItacPhotoFieldByName(photoName: string): string {
    if (photoName.includes('detalle')) {
        return 'audio_detalle';
    }
    if (photoName.includes('foto_1')) {
        return 'foto_1';
    }
    if (photoName.includes('foto_2')) {
        return 'foto_2';
    }
    if (photoName.includes('foto_3')) {
        return 'foto_3';
    }
    if (photoName.includes('foto_4')) {
        return 'foto_4';
    }
    if (photoName.includes('foto_5')) {
        return 'foto_5';
    }
    if (photoName.includes('foto_6')) {
        return 'foto_6';
    }
    if (photoName.includes('foto_7')) {
        return 'foto_7';
    }
    if (photoName.includes('foto_8')) {
        return 'foto_8';
    }
    if (photoName.includes('thumbnail')) {
        return 'thumbnail';
    }
    if (photoName.includes('foto_9') || photoName.includes('sign')) {
        return 'foto_9';
    }
    return '';
}

export function getItacPhotoFields(): string[] {
    return [
        'foto_1',
        'foto_2',
        'foto_3',
        'foto_4',
        'foto_5',
        'foto_6',
        'foto_7',
        'foto_8',
        'foto_9',
    ];
}

export function convertFromOldItacStructure(
    oldStructure: any,
    companyId: number,
    managerId: number
): Itac {
    let itac: any = {};

    itac['codigo_itac'] = oldStructure['codigo_itac'];
    itac['itac'] = oldStructure['itac'];
    itac['acceso'] = oldStructure['acceso'];
    itac['descripcion'] = oldStructure['descripcion'];
    itac['nombre_empresa_administracion'] = oldStructure['nombre_empresa_administracion'];
    itac['nombre_responsable_administracion'] = oldStructure['nombre_responsable_administracion'];
    itac['telefono_fijo_administracion'] = oldStructure['telefono_fijo_administracion'];
    itac['telefono_movil_administracion'] = oldStructure['telefono_movil_administracion'];
    itac['direccion_oficina'] = oldStructure['direccion_oficina'];
    itac['correo_administracion'] = oldStructure['correo_administracion'];
    itac['nombre_presidente'] = oldStructure['nombre_presidente'];
    itac['vivienda_presidente'] = oldStructure['vivienda_presidente'];
    itac['telefono_fijo_presidente'] = oldStructure['telefono_fijo_presidente'];
    itac['telefono_movil_presidente'] = oldStructure['telefono_movil_presidente'];
    itac['correo_presidente'] = oldStructure['correo_presidente'];
    itac['nombre_encargado'] = oldStructure['nombre_encargado'];
    itac['vivienda_encargado'] = oldStructure['vivienda_encargado'];
    itac['telefono_fijo_encargado'] = oldStructure['telefono_fijo_encargado'];
    itac['telefono_movil_encargado'] = oldStructure['telefono_movil_encargado'];
    itac['correo_encargado'] = oldStructure['correo_encargado'];
    itac['acceso_ubicacion_ubicacion'] = oldStructure['acceso_ubicacion_ubicacion'];
    itac['acceso_ubicacion_acceso'] = oldStructure['acceso_ubicacion_acceso'];
    itac['extra_acceso_ubicacion'] = oldStructure['extra_acceso_ubicacion'];
    itac['acceso_ubicacion_nota'] = oldStructure['acceso_ubicacion_nota'];
    itac['siempre_abierto'] = oldStructure['siempre_abierto'];
    itac['tipo_llave'] = oldStructure['tipo_llave'];
    itac['extras_llaves'] = oldStructure['extras_llaves'];
    itac['llaves_nota'] = oldStructure['llaves_nota'];
    itac['espacio_para_trabajar'] = oldStructure['espacio_para_trabajar'];
    itac['desague'] = oldStructure['desague'];
    itac['extras_desague'] = oldStructure['extras_desague'];
    itac['iluminacion'] = oldStructure['iluminacion'];
    itac['extras_iluminacion'] = oldStructure['extras_iluminacion'];
    itac['estado_de_conservacion_nota'] = oldStructure['estado_de_conservacion_nota'];
    itac['tubo_de_alimentacion'] = oldStructure['tubo_de_alimentacion'];
    itac['colector'] = oldStructure['colector'];
    itac['tuberias_de_entrada_contador'] = oldStructure['tuberias_de_entrada_contador'];
    itac['tuberias_de_salida_contador'] = oldStructure['tuberias_de_salida_contador'];
    itac['estado_de_tuberias_nota'] = oldStructure['estado_de_tuberias_nota'];
    itac['valvula_general'] = oldStructure['valvula_general'];
    itac['extras_valvula_general'] = oldStructure['extras_valvula_general'];
    itac['valvula_entrada'] = oldStructure['valvula_entrada'];
    itac['extras_valvula_entrada'] = oldStructure['extras_valvula_entrada'];
    itac['valvula_salida'] = oldStructure['valvula_salida'];
    itac['extras_valvula_salida'] = oldStructure['extras_valvula_salida'];
    itac['valvula_antiretorno'] = oldStructure['valvula_antiretorno'];
    itac['extras_valvula_antiretorno'] = oldStructure['extras_valvula_antiretorno'];
    itac['estado_de_valvulas_nota'] = oldStructure['estado_de_valvulas_nota'];
    itac['descripcion_foto_1'] = oldStructure['descripcion_foto_1'];
    itac['descripcion_foto_2'] = oldStructure['descripcion_foto_2'];
    itac['descripcion_foto_3'] = oldStructure['descripcion_foto_3'];
    itac['descripcion_foto_4'] = oldStructure['descripcion_foto_4'];
    itac['descripcion_foto_5'] = oldStructure['descripcion_foto_5'];
    itac['descripcion_foto_6'] = oldStructure['descripcion_foto_6'];
    itac['descripcion_foto_7'] = oldStructure['descripcion_foto_7'];
    itac['descripcion_foto_8'] = oldStructure['descripcion_foto_8'];
    itac['foto_1'] = oldStructure['foto_1'];
    itac['foto_2'] = oldStructure['foto_2'];
    itac['foto_3'] = oldStructure['foto_3'];
    itac['foto_4'] = oldStructure['foto_4'];
    itac['foto_5'] = oldStructure['foto_5'];
    itac['foto_6'] = oldStructure['foto_6'];
    itac['foto_7'] = oldStructure['foto_7'];
    itac['foto_8'] = oldStructure['foto_8'];
    itac['foto_9'] = oldStructure['foto_9'];
    itac['nombre_firmante'] = oldStructure['nombre_firmante'];
    itac['carnet_firmante'] = oldStructure['carnet_firmante'];
    itac['nuevo_citas'] = oldStructure['nuevo_citas'];
    itac['zona'] = oldStructure['zona'];
    itac['tipo'] = oldStructure['tipo'];
    itac['audio_detalle'] = oldStructure['audio_detalle'];

    if (oldStructure['puntuacion_seccion1']) itac['puntuacion_seccion1'] = parseFloat(oldStructure['puntuacion_seccion1']);
    if (oldStructure['puntuacion_seccion2']) itac['puntuacion_seccion2'] = parseFloat(oldStructure['puntuacion_seccion2']);
    if (oldStructure['puntuacion_seccion3']) itac['puntuacion_seccion3'] = parseFloat(oldStructure['puntuacion_seccion3']);
    if (oldStructure['puntuacion_seccion4']) itac['puntuacion_seccion4'] = parseFloat(oldStructure['puntuacion_seccion4']);
    if (oldStructure['puntuacion_seccion5']) itac['puntuacion_seccion5'] = parseFloat(oldStructure['puntuacion_seccion5']);
    if (oldStructure['puntuacion']) itac['puntuacion'] = parseFloat(oldStructure['puntuacion']);
    if (oldStructure['puntos_agua_total']) itac['puntos_agua_total'] = parseFloat(oldStructure['puntos_agua_total']);
    if (oldStructure['puntos_agua_con_contador']) itac['puntos_agua_con_contador'] = parseFloat(oldStructure['puntos_agua_con_contador']);
    if (oldStructure['puntos_agua_con_contador_mas_tarea']) itac['puntos_agua_con_contador_mas_tarea'] = parseFloat(oldStructure['puntos_agua_con_contador_mas_tarea']);
    
    itac['fecha_importacion'] = new Date(oldStructure['fecha_importacion']);
    itac['fecha_ejecucion'] = new Date(oldStructure['fecha_ejecucion']);
    itac['fecha_cierre'] = new Date(oldStructure['fecha_cierre']);
    itac['fecha_informe'] = new Date(oldStructure['fecha_informe']);
    itac['fecha_hora_cita'] = new Date(oldStructure['fecha_hora_cita']);
    
    itac['status_itac'] = task_status.IDLE;
    itac['date_time_modified'] = new Date(oldStructure['date_time_modified']);
    itac['geolocalizacion'] = myLatLngFromString(oldStructure['geolocalizacion']);
    itac['prioridad'] = priorityFromString(oldStructure['prioridad']);
    
    itac['company'] = companyId;
    itac['gestor'] = managerId;

    return itac;
}

