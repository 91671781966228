/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface MultipleOptionsDialogData {
    placeHolderText: '';
    options: [];
    currentOptionsSelected: [];
}

@Component({
    selector: 'app-multiple-selector',
    templateUrl: './multiple-selector.component.html',
    styleUrls: ['./multiple-selector.component.scss'],
})
export class MultipleSelectorComponent implements OnInit {
    @Input() placeHolderText = '';
    @Input() options = [];

    formData = new FormGroup({
        currentOptionsSelected: new FormControl(),
    });
    constructor(@Inject(MAT_DIALOG_DATA) public data: MultipleOptionsDialogData) {
        this.placeHolderText = data.placeHolderText;
        this.options = data.options;
        this.formData.controls['currentOptionsSelected'].setValue(data.currentOptionsSelected);
    }

    ngOnInit(): void {}

    onSelectedOption(option: string) {}
    cancelDialog() {}
}
